


  .ripple {
    position: relative;
    display: inline-block;
}
.ripple:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px;
    opacity: 0;
}
.ripple:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px;
    z-index: 0;
    opacity: 0;
}

.up.ripple:after {
    animation: greenanimate 1.5s 0.75s linear infinite;
}


.up.ripple:before {
    animation: greenanimate 1.5s linear infinite;
}


@keyframes greenanimate {
    0% {
        transform: scale(0.8);
        opacity: 1;
        border: 1.5px solid rgba(200, 200, 200, 0.8);
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
        border: 1.5px solid rgba(200, 200, 200, 0.8);
    }
}

$color: #001C36;

.get-started-button {
	display: inline-block;
	letter-spacing: .03rem;
	transition: all .3s;
	position: relative;
	overflow: hidden;
	color: #fff;
	z-index: 1;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color;
		z-index: -2;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: 100%;
		background-color: #00FFFF;
		transition: all .3s;
		z-index: -1;
	}
	&:hover {
		color: #000;
		&:before {
			width: 100%;
		}
	}
}
