span.partner-span {
    background: linear-gradient(91.91deg, #00FF66 8.25%, #0085FF 89.51%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* // text-fill-color: transparent; */
}

/* .slideshowSlider {
    transition: ease 1000ms;
} */

.bg-animation {
    width: 0%;
    animation: bgAnimate 9s linear 1;
    background-color: #006ACC;
    height: 8px;
    border-radius: 12px;
}

@keyframes bgAnimate {
    0% {
        width: 0%;
    }
    20% {
        width: 20%;
    }
    40% {
        width: 40%;
    }
    60% {
        width: 60%;
    }
    80% {
        width: 80%;
    }
    100% {
        width: 100%;
    }
  }

  .bg-animation2 {
    width: 0%;
    animation: bgAnimate 4.5s linear 1;
    background-color: #006ACC;
    height: 8px;
    border-radius: 12px;
}

.slide-show-pub {
    /* transition: ease 100ms; */
    transition: all .9s linear,
}


.slider {

    /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
    /* height: 100px; */
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
  }

  .slide-track {
    display: inline-block;
    display: flex;
    animation: move 10s linear infinite;
    /* white-space: nowrap; */
  }

  .slide-track > * {
    /* padding: 2vw; */
    display: inline-block;
  }

  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  /* .slider::before,
  .slider::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  } */

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }
