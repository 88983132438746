@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Inter;
    scroll-behavior: smooth;
    font-size: 12px;
}
@media (min-width: 1024px) {
    html,
    body {
        font-size: calc(0.008333333 * 100vw);
    }
}

.dark ::-webkit-scrollbar {
    height: 8px;
    background: #212121;

    width: 8px;
    border-radius: 900px;
}

.dark ::-webkit-scrollbar-thumb {
    background: #000;

    border-radius: 900px;
}

.dark ::-webkit-scrollbar-corner {
    background: #000;
}

::-webkit-scrollbar {
    height: 8px;
    background: #fff;

    width: 8px;
    border-radius: 900px;
}

::-webkit-scrollbar-thumb {
    background: #bbb;

    border-radius: 900px;
}

::-webkit-scrollbar-corner {
    background: #fff;
}

#root {
    width: 100%;
    height: 100%;
    background-color: "#42495B";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.table-class {
    padding-bottom: 20px !important;
    background-color: red;
}

.react-calendar .react-calendar__navigation {
    padding-bottom: 1.75rem;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    padding-left: 0.625rem;
    font-size: 1.25rem;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    padding-right: 0.625rempx;
    font-size: 1.25rem;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar .react-calendar__month-view__days button {
    padding: 0.375rem 0.125rem;
}

.react-calendar .react-calendar__tile {
    display: flex !important;
    flex-basis: auto !important;
    justify-content: center;
    align-items: center;
    margin: 0.3125rem 0.25rem;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.125rem;
    font-size: 0.875rem;
}

.react-calendar__month-view__weekdays__weekday {
    margin-bottom: 0.5rem;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    color: #000;
}

.react-calendar.calender-dark .react-calendar__month-view__weekdays__weekday abbr {
    color: #fff !important;
}

.react-calendar .react-calendar__tile {
    width: 1.875rem;
    height: 1.75rem;
}

.react-calendar .react-calendar__tile:hover {
    border: 1px solid #aaa;
    background-color: #aaa !important;
    border-radius: 50%;
    color: #fff !important;
}

.react-calendar.calender-dark .react-calendar__tile:hover {
    border: 1px solid #73a3c8;
    background-color: #73a3c8 !important;
    border-radius: 50%;
    color: #b3ffff !important;
}
.react-calendar.calender-dark .react-calendar__tile:hover {
    background-color: #62626279 !important;
    border: 1px solid #62626279;
    color: #ffffff !important;
}

.react-calendar .react-calendar__tile.react-calendar__tile--active {
    background-color: #00ffff !important;
    color: #000 !important;
    border-radius: 50% !important;
    font-size: 0.87rem;
    padding: 0.0625rem;
}

.monitor-calendar.react-calendar .react-calendar__tile.react-calendar__tile--active {
    background-color: #626262 !important;
    color: #fff !important;
}

.react-calendar.calender-light .react-calendar__tile.react-calendar__tile--active {
    background-color: #1d6ba6 !important;
    color: #fff !important;
    padding: 0.5rem 1px !important;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    max-width: 20rem;
    padding: 0.5rem;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    padding-left: 0px;
    width: 1.5rem;
    padding-right: 0.625rem;
}

.react-calendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    padding-left: 0.625rem;
}

.react-calendar .react-calendar__navigation__label {
    max-width: 11.875rem;
    flex-grow: 0;
}

.calender-dark {
    color: white !important;
}
.react-calendar__tile.react-calendar__decade-view__years__year.single-tile {
    width: 3.125rem !important;
    padding: 0.625rem 1.25rem;
    border-radius: 0.375rem !important;
    cursor: pointer;
}

.react-calendar__tile.react-calendar__year-view__months__month.single-tile {
    width: 5.625rem !important;
    padding: 0.625rem 1.25rem;
    border-radius: 0.375rem !important;
    cursor: pointer;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth.single-tile {
    color: #aaa;
    cursor: pointer;
}

.react-calendar__tile.react-calendar__century-view__decades__decade.single-tile {
    width: 8.4375rem;
    border-radius: 0.625rem;
    cursor: pointer;
}

button.react-calendar__tile.react-calendar__month-view__days__day.single-tile:disabled {
    color: gray;
    cursor: not-allowed;
}

button.react-calendar__tile.react-calendar__month-view__days__day.single-tile:disabled:hover {
    background-color: transparent !important;
    cursor: not-allowed;
}
.tooltip {
    display: none;
}
:hover > .tooltip {
    display: flex;
}
