/* Made by Jimmy Gillam */
$checked-color: #34b93d;
$unchecked-color: #fff;
$checkbox-height: 13px;
$background-color:#000;
$font-color:#efefef;
$duration: .2s;




/* Checkmark style starts */

@-moz-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
}

@-webkit-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
}

@keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2;  }
}

@keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}
@-webkit-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}
@-moz-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}

input[type=checkbox]{
    display:none;
}

.check-box {
    height: $checkbox-height;
    width: $checkbox-height;
    background-color: transparent;
    border: $checkbox-height * .1 solid $unchecked-color;
    border-radius: 2px;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease $duration/2;
    -o-transition: border-color ease $duration/2;
    -webkit-transition: border-color ease $duration/2;
    transition: border-color ease $duration/2;
    cursor:pointer;

    &::before, &::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: $checkbox-height * .2;
        background-color: $checked-color;
        display: inline-block;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        -o-transform-origin: left top;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        -webkit-transition: opacity ease .5;
        -moz-transition: opacity ease .5;
        transition: opacity ease .5;
    }

    &::before {
        top:$checkbox-height * .72;
        left: $checkbox-height * .41;
        box-shadow: 0 0 0 $checkbox-height * .05 $background-color;
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }

    &::after {
        top: $checkbox-height * .37;
        left: $checkbox-height * .05;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

input[type=checkbox]:checked + .check-box,
.check-box.checked{
    border-color:$checked-color;

    &::after{
        height: $checkbox-height * .5;
        -moz-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -o-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -webkit-animation: dothabottomcheck $duration/2 ease 0s forwards;
        animation: dothabottomcheck $duration/2 ease 0s forwards;
    }

    &::before{
        height: $checkbox-height * 1.2;
        -moz-animation: dothatopcheck $duration ease 0s forwards;
        -o-animation: dothatopcheck $duration ease 0s forwards;
        -webkit-animation: dothatopcheck $duration ease 0s forwards;
        animation: dothatopcheck $duration ease 0s forwards;
    }
}

// .access-submit-btn{

//   &:after {
//     left: 120%;
//     transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   }
// }

.access-submit-btn {
    // animation: demo 1.3s linear infinite;

    overflow: hidden;
    position: relative;

    &:after {
        background-image: linear-gradient(to right, #0084FF , #01B2FF);
        content: "";
        height: 155px;
        left: -70px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 0;
        animation: demo 0.9s linear infinite;
      }
}



@keyframes demo {
    0% {
        background-image: linear-gradient(to right, #01A2FF , #01B2FF);
        content: "";
        height: 155px;
        left: -70px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 60px;
        z-index: 0;

    }

    50% {
        background-color: #01A2FF;
    }
    100% {
        left: 120%;
        transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}


/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
 .dot-falling {
    position: relative;
    left: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 9999px 0 0 0 #fff;
    animation: dotFalling 1s infinite linear;
    animation-delay: .1s;
  }

  .dot-falling::before, .dot-falling::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-falling::before {
    width: 8px;
    height: 8px;
    left: 2px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotFallingBefore 1s infinite linear;
    animation-delay: 0s;
  }

  .dot-falling::after {
    width: 8px;
    height: 8px;
    left: 0px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dotFallingAfter 1s infinite linear;
    animation-delay: .2s;
  }

  @keyframes dotFalling {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 #fff;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }

  @keyframes dotFallingBefore {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 #fff;
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }

  @keyframes dotFallingAfter {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 #fff;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
  }

