$color: #001C36;

.partner-button {
	display: inline-block;
	letter-spacing: .03rem;
	transition: all .3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-color: $color;
		z-index: -2;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: 100%;
		background-color: #00FFFF;
		transition: all .3s;
		z-index: -1;
	}
	&:hover {
		color: #000;
		&:before {
			width: 100%;
		}
	}
}